import './about.css'



export default function About() {
    return (
        <>
        
        <header className="aboutMasthead" style= {{backgroundImage: `url(${process.env.PUBLIC_URL + "/images/universe.jpg"})`}}>
        <div className="container position-relative px-4 px-lg-5">
             <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <div className="aboutTitles">
                            <h1 className="aboutTitle">About Me</h1>
                            <span className="aboutSubheading">Amy who?</span>
                        </div>
                    </div>
               
        </div>
        </div>
        
        </header>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto">
                <p className="aboutContent">I am passionate about creating environments where students and teachers thrive. 
                    My work in education has been shaped by diverse experiences, from teaching in high-needs schools to navigating the 
                    unique needs of my own two children. These experiences have deepened my commitment to fostering communities where each person
                    feels seen, valued, and capable. 
                </p>
                <p className="aboutContent">Outside of work, I love spending time with my famly. We enjoy building, learning, and gaming
                    togther. Lately, I've been diving into the exciting challenge of becoming a Dungeon Master.  </p>
                
                </div>
            </div>
        </div>
        </>
        
        
     
    )
}
