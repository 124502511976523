/* eslint-disable jsx-a11y/anchor-has-content */
import "./sidebar.css";




export default function Sidebar() {
  
  return (
    <div className="sidebar">
      <div className="sidebarItem">
        <span className="sidebarTitle">About Me</span>
        <img className="sidebarImage" src={`${process.env.PUBLIC_URL}/images/amypic.png`} alt="Amy T" />
        <p className="sidebarParagraph">
          I'm a mom, educator and aspiring Dungeon Master. I nurture growth, lead with heart, and embrace the magic of adventure.
        </p>
      </div>
      
      <div className="sidebarItem">
      <span className="sidebarTitle">Let's Connect</span>
        <div className="sidebarSocial">
          <a className="link sidebarIcon fab fa-github-square" href="https://github.com/cookiebrains"></a>
          <a className="link sidebarIcon fab fa-goodreads" href="https://www.goodreads.com/user/show/234151-amy"></a>

        </div>
      </div>
    </div>
  );
}
